import { showNotification } from '@mantine/notifications';

const successToast = (message: any) =>
    showNotification({
        disallowClose: true,
        autoClose: 2000,
        message: message,
        color: 'teal',
        py: 'md',
        radius: 'sm',
    });

const errorToast = (message: any) =>
    showNotification({
        disallowClose: true,
        autoClose: 2000,
        message: message,
        color: 'red',
        py: 'sm',
        radius: 'sm',
    });

export { successToast, errorToast };
