import { TextInput, Button, Group, Text, Select, Grid, Card, Modal, FileButton } from '@mantine/core';
import { matches, useForm } from '@mantine/form';
import { errorToast, successToast } from '../../../common/toast/toast';
import { APISeniorCitizenInfoPost, APISeniorCitizenInfoPut } from '../../../../api/seniorcitizeninfo';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { IconCamera, IconCloudUpload, IconX } from '@tabler/icons';
import { PreviewImage } from './PreviewImage';
import { Camera } from 'react-camera-pro';
// @ts-ignore
import image1 from '../../../../assets/images/image1.jpg';
import { bloodGroupData, gender, guardiansRelationNP } from '../../../../utils/constants/Constants';
import { useLocation } from 'react-router';
import { EmployeePositionDTO, UserInfoDTO } from '../../../../utils/helpers/UserInfoFormater';
import { APIEmployeePosition } from '../../../../api/employee';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';

export const SeniorCitizenInfoForm = () => {
    const { state } = useLocation();
    const [employeePost, setEmployeePost] = useState<any[]>([]);
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);

    const card_types = JSON.parse(sessionStorage.getItem('card-type') ?? '');
    const card_id = card_types?.find((v: any) => v.card_nameEN === 'senior citizen')?.id;

    const form = useForm({
        initialValues: {
            identity_no: '',
            full_name: '',
            address: '',
            district: 'उदयपुर',
            province: 'कोशी प्रदेश',
            ward_no: '',
            date_of_birth: '',
            gender: '',
            date_of_issue: '',
            issued_district: '',
            citizenship_no: '',
            spouses_name: '',
            guardians_name: '',
            guardians_address: '',
            guardians_relation: '',
            contact_no: '',
            blood_group: '',
            disease: '',
            prescribed_medicine: '',
            care_center: '',
            card_type_id: card_id,
            approved_by: '',
        },

        validate: {
            full_name: matches(/[\u0900-\u097F]/, 'पुरा नाम नेपलीमा लेख्नुहोस्!'),
            guardians_name: matches(/[\u0900-\u097F]+$/, 'नेपलीमा लेख्नुहोस्'),
        },
    });

    const employeePosition = async () => {
        const res: any = await APIEmployeePosition();
        const data = res.data.data.map((val: any) => EmployeePositionDTO.receive(val));

        setEmployeePost(data.map((v: any) => UserInfoDTO.receive(v)));
    };

    useEffect(() => {
        employeePosition();
        if (state !== null || undefined) {
            form.setValues(UserInfoDTO.receive(state));
        }
    }, [state]);

    const [selectedImage, setSelectedImage] = useState<Blob>();
    //conversion of image
    function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new File(byteArrays, 'image', { type: contentType });
    }

    const submitHandler = async (data: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value as any);
        }
        if (!!selectedImage && typeof selectedImage == 'string') {
            // @ts-ignore
            let image1 = selectedImage?.split(',');
            var image2 = image1[1];

            const file = b64toBlob(image2, 'image/jpeg', 512);
            formData.append('image', file);
        } else {
            formData.append('image', selectedImage as any);
        }

        try {
            if (!!state?.id) {
                const res: any = await APISeniorCitizenInfoPut(formData, Number(state?.id));
                res?.status == 1
                    ? (navigate('/senior-citizen'), successToast('तपाईको निबेदन पेस भएको छ|'))
                    : errorToast('Something went wrong, please try again!');
            } else {
                const res: any = await APISeniorCitizenInfoPost(formData);
                res?.status == 1
                    ? (navigate('/senior-citizen'), successToast('तपाईको निबेदन पेस भएको छ|'))
                    : errorToast('Something went wrong, please try again!');
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const camera = useRef<any>(null);

    // @ts-ignore
    return (
        <Grid className={'m-[0px] h-full w-full overflow-y-scroll '}>
            <Modal opened={opened} onClose={() => setOpened(false)} centered size={350} className={'w-full'}>
                <div className={'h-[400px] w-[400px] flex flex-col mx-auto items-center justify-center'}>
                    <Camera
                        aspectRatio={4 / 3}
                        ref={camera}
                        facingMode={'environment'}
                        errorMessages={{
                            noCameraAccessible: 'camera is not available',
                            permissionDenied: 'permissionDenied',
                            switchCamera: 'camera switch',
                            canvas: 'canvas',
                        }}
                    />
                    <Group className={'flex justify-around w-[300px] mb-md'}>
                        <Button
                            size={'xs'}
                            className={'bg-primary-700 mt-sm '}
                            onClick={() => {
                                setSelectedImage(camera.current.takePhoto()), setOpened(false);
                            }}
                        >
                            Take photo
                        </Button>
                        <Button size={'xs'} className={'bg-primary-700 mt-sm '} onClick={() => setOpened(false)}>
                            Cancel
                        </Button>
                    </Group>
                </div>
            </Modal>
            <Grid.Col className={''} offset={2} span={11}>
                <Card>
                    <Grid.Col offset={4}>
                        <Text className={'font-black text-xl underline'}> जेष्ठ नागरिक दर्ता </Text>
                    </Grid.Col>
                    <form className={'w-full flex '} onSubmit={form.onSubmit((values) => submitHandler(values))}>
                        <Grid.Col span={4}>
                            <TextInput
                                className={'py-xs mt-md'}
                                withAsterisk
                                label="प.प.नं"
                                mask="00-00"
                                placeholder="परिचय पत्र नं"
                                {...form.getInputProps('identity_no')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="नागरिकता नं."
                                placeholder=""
                                {...form.getInputProps('citizenship_no')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="पुरा नाम"
                                placeholder="पुरा नाम लेख्नुहोस्"
                                {...form.getInputProps('full_name')}
                            />
                            <Select
                                className={'py-xs'}
                                withAsterisk
                                label="लिङ्ग"
                                placeholder="छान्नुहोस्"
                                data={gender}
                                {...form.getInputProps('gender')}
                            />

                            <div className="mantine-InputWrapper-root mantine-TextInput-root py-xs mantine-14eweac">
                                <label
                                    className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"
                                    htmlFor="mantine-r1"
                                    id="mantine-r1-label"
                                >
                                    जन्म मिति (बि.सं)
                                    <span
                                        className="mantine-u5apz8 mantine-InputWrapper-required mantine-TextInput-required"
                                        aria-hidden="true"
                                    >
                                        *
                                    </span>
                                </label>
                                <div className="mantine-Input-wrapper mantine-TextInput-wrapper mantine-12sbrde">
                                    <NepaliDatePicker
                                        inputClassName={`mantine-Input-input mantine-TextInput-input mantine-1j4mrfo`}
                                        // value={date}
                                        // onChange={(value: string) => {setDate(value),console.log(value)}}
                                        {...form.getInputProps('date_of_birth')}
                                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                                    />
                                </div>
                            </div>

                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="प्रदेश"
                                placeholder=""
                                disabled
                                {...form.getInputProps('province')}
                            />

                            <TextInput
                                className={'py-xs'}
                                label="ठेगाना"
                                placeholder="ठेगाना"
                                {...form.getInputProps('address')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="वार्ड नं."
                                placeholder="वार्ड नं."
                                {...form.getInputProps('ward_no')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="संरक्षकको नाम"
                                placeholder="संरक्षकको पुरा नाम लेख्नुहोस् "
                                {...form.getInputProps('guardians_name')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="संरक्षकको ठेगाना"
                                placeholder="संरक्षकको पुरा ठेगाना लेख्नुहोस् "
                                {...form.getInputProps('guardians_address')}
                            />
                            {/*<TextInput*/}
                            {/*  className={'py-xs'}*/}
                            {/*  withAsterisk*/}
                            {/*  label="संरक्षक संगको नाता"*/}
                            {/*  placeholder="संरक्षक संगको नाता लेख्नुहोस् "*/}
                            {/*  {...form.getInputProps('guardians_relation')}*/}
                            {/*/>*/}
                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="संरक्षकसंगको नाता "
                                data={guardiansRelationNP}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('guardians_relation')}
                            />
                        </Grid.Col>
                        <Grid.Col offset={0.5} span={4}>
                            <div className={'flex mb-sxs'}>
                                <div>
                                    <PreviewImage
                                        src={
                                            !!selectedImage && typeof selectedImage == 'object'
                                                ? URL.createObjectURL(selectedImage)
                                                : selectedImage ?? image1
                                        }
                                    />
                                </div>
                                <Group className={'flex flex-col '}>
                                    {!!selectedImage ? (
                                        <Button
                                            onClick={() => setSelectedImage(undefined)}
                                            className={
                                                'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                            }
                                        >
                                            <IconX className={'mr-xs'} /> Remove image
                                        </Button>
                                    ) : (
                                        <FileButton onChange={(e: any) => setSelectedImage(e)} accept="image/png,image/jpeg">
                                            {(props) => (
                                                <Button
                                                    className={
                                                        'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                                    }
                                                    {...props}
                                                >
                                                    <IconCloudUpload className={'mr-xs'} /> Upload image
                                                </Button>
                                            )}
                                        </FileButton>
                                    )}
                                    <Button
                                        className={
                                            'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                        }
                                        onClick={() => setOpened(true)}
                                    >
                                        <IconCamera className={'mr-xs font-normal'} />
                                        {selectedImage ? 'Retake Picture' : 'Take Picture'}
                                    </Button>
                                </Group>
                            </div>
                            {/* <TextInput
                className={'py-xs'}
                withAsterisk
                label="जारी मिति"
                placeholder="जारी मिति उललेखित गर्नुहोस्"
                {...form.getInputProps('date_of_issue')}
              /> */}

                            <div className="mantine-InputWrapper-root mantine-TextInput-root py-xs mantine-14eweac">
                                <label
                                    className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"
                                    htmlFor="mantine-r1"
                                    id="mantine-r1-label"
                                >
                                    जारी मिति (बि.सं)
                                    <span
                                        className="mantine-u5apz8 mantine-InputWrapper-required mantine-TextInput-required"
                                        aria-hidden="true"
                                    >
                                        *
                                    </span>
                                </label>
                                <div className="mantine-Input-wrapper mantine-TextInput-wrapper mantine-12sbrde">
                                    <NepaliDatePicker
                                        inputClassName={`mantine-Input-input mantine-TextInput-input mantine-1j4mrfo`}
                                        // value={date}
                                        // onChange={(value: string) => {setDate(value),console.log(value)}}
                                        {...form.getInputProps('date_of_issue')}
                                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                                    />
                                </div>
                            </div>

                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="जारी जिल्ला"
                                placeholder="जारी जिल्ला उललेखित गर्नुहोस्"
                                {...form.getInputProps('issued_district')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="पति/पत्नीको नाम"
                                placeholder="पति/पत्नीको नाम लेख्नुहोस्"
                                {...form.getInputProps('spouses_name')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="रोग"
                                placeholder="रोग भएमा उललेखित गर्नुहोस्"
                                {...form.getInputProps('disease')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="निर्धारित औषधि"
                                placeholder="निर्धारित औषधि भएमा उललेखित गर्नुहोस्"
                                {...form.getInputProps('prescribed_medicine')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="हेरचाह केन्द्र"
                                placeholder="हेरचाह केन्द्रमा बसेको भए सोको विवरण"
                                {...form.getInputProps('care_center')}
                            />
                            <Select
                                className={'py-xs'}
                                label={'रक्त समूह'}
                                data={bloodGroupData}
                                placeholder={'छान्नुहोस्'}
                                {...form.getInputProps('blood_group')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="सम्पर्क नं."
                                placeholder="९८७६५४३२१०"
                                {...form.getInputProps('contact_no')}
                            />
                            <Select
                                className={'py-xs'}
                                label="प्रमाणित गर्ने "
                                nothingFound="No result found"
                                placeholder="छान्नुहोस्"
                                data={employeePost}
                                {...form.getInputProps('approved_by')}
                            />
                            <Group position="right" mt="md">
                                <Button className={'bg-primary-700'} type="submit">
                                    Submit
                                </Button>
                            </Group>
                        </Grid.Col>
                    </form>
                </Card>
            </Grid.Col>
        </Grid>
    );
};
