import { Box, Button, Group, PasswordInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIResetPassword } from '../../../../api';
import { errorToast, successToast } from '../../../common/toast/toast';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const [urlValues] = useState({
        email: searchParams && searchParams.get('email'),
        token: searchParams && searchParams.get('token'),
    });

    const navigate = useNavigate();

    const form = useForm({
        initialValues: {
            password: '',
            password_confirmation: '',
        },

        validate: {
            password: (value) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(value)
                    ? null
                    : 'Password should consist of minimum 8 characters, at least one uppercase letter, one lowercase letter and one number',
            password_confirmation: (value, values) => (value !== values.password ? 'Passwords did not match' : null),
        },
    });

    const formData = new FormData();
    Object.keys(form.values).forEach((key) => formData.append(key, (form.values as any)[key]));
    formData.append('email', (urlValues.email as any) ?? null);
    formData.append('token', (urlValues.token as any) ?? null);

    const submitHandler = async () => {
        const res: any = await APIResetPassword(formData);
        if (res.status === 1) {
            successToast(res.message);
            navigate('/auth');
        } else {
            errorToast(res.message);
            navigate('/auth/send-forgot-password');
        }
    };

    return (
        <Box className="grid place-items-center h-[100vh]">
            <form className="w-[30rem]" onSubmit={form.onSubmit(() => submitHandler())}>
                <Group position="center" mb="md">
                    <Title order={2}>Reset Password</Title>
                </Group>
                <Box>
                    <PasswordInput
                        required
                        label="Password"
                        name="password"
                        placeholder="Password"
                        size="md"
                        mt="md"
                        mb="md"
                        {...form.getInputProps('password')}
                        autoComplete="on"
                    />
                    <PasswordInput
                        required
                        label="Confirm Password"
                        name="password_confirmation"
                        placeholder="Confirm Password"
                        size="md"
                        mt="md"
                        mb="md"
                        {...form.getInputProps('password_confirmation')}
                        autoComplete="on"
                    />
                </Box>
                <Group position="right">
                    <Button type="submit" className="bg-[#228be6]" style={{ width: '100%' }}>
                        Reset Password
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default ResetPassword;
