import ForgotPassword from '../modules/ForgotPassword';
import Login from '../modules/Login';
import ResetPassword from '../modules/ResetPassword';

const AUTH_ROUTES = [
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/send-forgot-password', element: <ForgotPassword /> },
    { path: '/forgot-password', element: <ResetPassword /> },
];

export default AUTH_ROUTES;
