import axiosInstance from './axiosInterceptor';

export const GetRequest = (url: string, config?: {}) => {
    return axiosInstance.get(url, config);
};

export const PostRequest = (url: string, config?: {}) => {
    return axiosInstance.post(url, config);
};

export const PutRequest = (url: string, config?: {}) => {
    return axiosInstance.put(url, config);
};

export const DeleteRequest = (url: string, config?: {}) => {
    return axiosInstance.delete(url, config);
};
