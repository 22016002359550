import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RoleLayout } from '../hoc';
import { loggedUser } from '../store/loggedUser/logged.user.action';
import { rootReducer } from '../store/rootReducer';
import { getRole } from '../utils/helpers/localStorage.helpers';

const PrivateRoutes = () => {
    const isRole = getRole();

    type ReduxState = ReturnType<typeof rootReducer>;

    type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;

    const dispatch = useDispatch<TypedDispatch>();

    useEffect(() => {
        dispatch(loggedUser());
    }, [dispatch]);

    return (
        <Routes>
            <Route path="/*" element={<RoleLayout role={isRole} />} />
            <Route path="*" element={'Page Not Found'} />
        </Routes>
    );
};

export default PrivateRoutes;
