import { useEffect, useState } from 'react';
import PrivateRoutes from './PrivateRoutes';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getToken } from '../utils/helpers/localStorage.helpers';
import AuthLayout from '../layouts/AuthLayout';
import { useSelector } from 'react-redux';
import PublicLayout from '../layouts/PublicLayout';
import { useLocation } from 'react-router';

type TypeMethod = boolean | null | string;

const MainRoute = () => {
    const data = useSelector((state: any) => state.authReducer);
    const isValidToken = !!data.token ? data.token : getToken();

    const [isLogin, setIsLogin] = useState<TypeMethod>(!!isValidToken);

    const location = useLocation();

    useEffect(() => {
        setIsLogin(!!isValidToken);
    }, [location.pathname]);

    return (
        <Routes>
            <Route path="/public/*" element={!isLogin ? <PublicLayout /> : <Navigate to="/" />} />
            <Route path="/auth/*" element={!isLogin ? <AuthLayout /> : <Navigate to="/" />} />
            <Route path="/*" element={isLogin ? <PrivateRoutes /> : <Navigate to="/auth" />} />
        </Routes>
    );
};

export default MainRoute;
