import { GetRequest, PostRequest } from '../services/httpRequest';

export const APIAuthenticatedUser = (data: any) => {
    return PostRequest('auth/login', data);
};

export const APILoggedUser = () => {
    return GetRequest('user');
};

export const APICardType = () => {
    return GetRequest('card');
};

export const APICardCount = () => {
    return GetRequest('user-info/cardtype/count');
};

export const APIChangePassword = (data: any) => {
    return PostRequest('user/details/change-password', data);
};

export const APISendForgotPassword = (data: any) => {
    return PostRequest('auth/send-forgot-password', data);
};

export const APIResetPassword = (data: any) => {
    return PostRequest('auth/forgot-password', data);
};
