export const saveToken = (val: string) => localStorage.setItem('token', val);

export const saveRole = (val: string) => localStorage.setItem('role', val);

export const saveUser = (val: string) => localStorage.setItem('user', JSON.stringify(val));

export const getToken = () => (localStorage.getItem('token') ? localStorage.getItem('token') : null);

export const getRole = () => (localStorage.getItem('role') ? localStorage.getItem('role') : null);

export const getUser = () => (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null);

export const clearStorage = () => localStorage.clear();
