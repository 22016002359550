import { bloodGroupData, gender } from '../../../../utils/constants/Constants';
import { IMAGE_URL } from '../../../../config/baseURL';

export const PrintSeniorCitizenInfo = (PrintSeniorCitizenInfo: any, governmentLogo: any, municipalityID: any) => {
    const user_image = `${IMAGE_URL}/storage/municipality/${municipalityID}/senior-citizen/image/${PrintSeniorCitizenInfo.image}`;
    const WinPrint: any = window.open();
    WinPrint.document.write(`
  <!DOCTYPE html>
    <html lang='en'>
    <head>
    <meta charset='UTF-8'>
    <meta http-equiv='X-UA-Compatible' content='IE=edge'>
    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
  <style>
      @media print {
       * {
       -webkit-print-color-adjust: exact;
   }
      @page {
        size: 5in 3.5in;
        margin: 0;
        display: flex;
      }
      html, body {
      size: 5in 3.5in;

      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-left: 0.49963in;
      margin-top: 0.1589in;
      margin-right: 0.0000393701in;
      margin-bottom: 0.089in;
      font-size: 12px;

}
      main{
  /*background: blue;*/
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 100vh;
  margin-top: 4mm;
    }
      .p-t{
      padding: 2px 0px;
      }
      /*.p-l{*/
      /*padding-left: 5px;*/
      /*}*/
      .contain{
      /*z-index: 100;      */
          width: 5in;
           height: 3.5in;
           padding: 3mm;
           line-height: 1.5rem;
           /*padding: 0.02in 0.091in ;*/
          border: 1px dashed #000;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          /*padding: 10px;*/
          page-break-after: always;
           margin-right: 5mm;
          margin-bottom: 4mm;
      }
    
          .contain1{
      /*background: green;*/
          width: 5in;
           height: 3.5in;
           line-height: 1.5rem;
           padding: 3mm;
          border: 1px dashed #000;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          /*justify-content: flex-end;*/
          page-break-after: always;
          margin-top: 12mm;
          margin-right: 3mm;
          margin-bottom: 3mm;
          
      }
    

            
      .header{
      margin: 0;
      padding: 0;
          display: flex;
          flex-direction: row;
          justify-content:space-between;
          align-items: center;
      }
      .header-logo{
          width: 80px ;
          margin-right: 10px;
          z-index: 100;
      }

      .header-detail{
      margin-right: 80px;
      padding-left: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      }
    
      .title{
          font-size: 13px;
          line-height: 15px;
          font-weight: bold;
          flex: 1;
          width: 70%;
          margin-right: 100px;
      }
      .bold{
      font-weight: bold;
      }

      .card-title{
          padding: 3px;
          width:46%;
          display: flex;
          justify-content: center;
          background: #333132;
          color: white;
          font-size: 13px;
          line-height: 12px ;
          z-index: 100;
          margin: 2mm auto;
      }

      .user-image{
      height:1.2in;
      width: 1.1in;
      border:1px solid black;
      margin-top: -10px;
      margin-right: 4px;
      z-index: 100;
      }
      
      img{
      height: inherit;
      width: inherit;
      object-fit: cover;
      z-index: 100;
      }
      .text-small{
      font-size: 9px;
      }
       .address-info{
      margin-right: 10px;
      }
      .card-main{
      margin-top: -0.2mm;
      display: flex;
      justify-content: space-between;
      }
      .flex{
      width: 100%;
      display: flex;
      /*align-items: center;*/
      }

      .left-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-basis: 100%;
        flex: 1;
      }
      .lose-case{
      margin-top: 3px;
      font-size: 8px;
      font-style: italic;
      text-align: center;
      }
      .office-stamp{
      height:1in;
      width: 1in;
      border:1px solid black;
      }
            .bottom{
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
}
    
      </style>
    </head>
    <body>
     <main>
        <!--frontside page view-->
        <div class='contain'>
            <div class='header'>
                <img class='header-logo'  src='${governmentLogo}' alt='government logo'>
                <div class='header-detail'>
                    <div class='title text-small '>नेपाल सरकार</div>
                        <div class='title'>कटारी नगरपालिका</div>
                        <div class='title'>नगर कार्यपालिकाको कार्यालय</div>
                        <div class='title text-small'>कटारी, उदयपुर</div>
                        <div class='title text-small'>कोशी प्रदेश, नेपाल</div>
                    </div>
                </div>
            <div class='card-title'>
                जेष्ठ नागरिक परिचय-पत्र
            </div>             
            <div class='card-main'>
                <div class='flex'>
                    <div class='left-content'>
                        <div class='p-t'>परिचय पत्रको नम्बर:
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.identity_no ?? ''}
                        </span>
                        </div>
                        <div class='p-t'>नाम, थर: 
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.full_name ?? ''}
                        </span>
                        </div>
                        <div class='p-t'>
                        <span class='address-info'>प्रदेश.: 
                            <span class='bold'>
                                कोशी
                            </span>
                        </span>
                        </div>
                        <div class='p-t'> जिल्ला: 
                        <span class='bold'>
                            उदयपुर
                        </span>
                        </div>
                        <div class='p-t'>
                        <span class='address-info'>ठेगाना: 
                            <span class='bold'>
                                ${PrintSeniorCitizenInfo?.address ?? ''}
                            </span>
                        </span>
                        </div>
                        <div class='p-t'>जन्म मिति: 
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.date_of_birth || '___________'}
                        </span>
                        </div>
                          <div class='p-t'>जारी मिति: 
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.date_of_issue || '___________'}
                        </span>
                        </div>
                    </div>
                    <div class='left-content'>
                        <div class='p-t'>नागरिकता नम्बर:
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.citizenship_no || '___________'}
                        </span>
                        </div>
                        <div class='p-t'>लिङ्ग: 
                        <span class='bold'>
                            ${gender.find((v) => v.value == PrintSeniorCitizenInfo?.gender)?.label || '____________'}
                        </span>
                        </div>
                        <div class='p-t'>पति/पत्नीको नाम: 
                        <span class='bold'>
                            ${PrintSeniorCitizenInfo?.spouses_name || '___________'}
                        </span>
                        </div>
                        <div class='p-t'>
                        <span class='address-info'>स्थानीय तह: 
                            <span class='bold'>
                                कटारी नगरपालिका
                            </span>
                        </span>
                        </div>
                        <div class='p-t'>
                        <span class='address-info'>वडा नं: 
                            <span class='bold'>
                                ${PrintSeniorCitizenInfo?.ward_no ?? ''}
                            </span>
                        </span>
                        </div>
                        <div class='p-t'>जारी जिल्ला : 
                        <span class='bold'>
                         ${PrintSeniorCitizenInfo?.issued_district} 
                        </span>
                        </div>
                    </div>
                </div>
                <div class='user-image'>
                    <img src='${user_image}' alt='user-image'>
                </div>
            </div>
            <div class='lose-case pr-t'>
                <span >"यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय निकायमा बुझाई दिनुहोला |"</span>
            </div>
        </div>
        <!--backside page view-->
        <div class='contain1'>         
            <div class='card-main' >
                <div class='flex'>
                    <div class='left-content'>
                        <div class='p-t'>
                            <span class='address-info'>हेरचाह केन्द्रमा बसेको भए सोको विवरण :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.care_center ?? ''}
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>संरक्षकको नाम, थर :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.guardians_name ?? ''}
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>ठेगाना :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.guardians_address ?? ''}
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>सम्पर्क नं. :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.contact_no ?? ''}
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>रक्त समूह :
                                <span class='bold'>
                                    ${
                                        bloodGroupData.find((v) => v.value == PrintSeniorCitizenInfo?.blood_group)?.label ||
                                        '________________'
                                    }
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>रोग भए रोगको नाम :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.disease ?? ''}
                                </span>
                            </span>
                        </div>
                        <div class='p-t'>
                            <span class='address-info'>सेवन गरिएको औषधीको नाम :
                                <span class='bold'>
                                    ${PrintSeniorCitizenInfo?.prescribed_medicine ?? ''}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='bottom'>
                <div class='m-t'>
                    <div class='p-t'><u>प्रमाणित गर्नेको</u>
                        <div class='p-t'>दस्तखत :</div>
                        <div class='p-t'>नाम, थर :</div>
                        <div class='p-t'>पद :</div>
                    </div>
                </div>
                <div class='office-stamp m-t'>
                    <div class='m-t f-c'>कार्यालयको छाप</div>
                </div>
            </div>
        </div>
  </main>
  </body>
  </html>`);
    setTimeout(function () {
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }, 250);
};
