// @ts-ignore
import image from '../../../assets/images/logo.png';
export const Logo = () => {
    const style = {
        width: '150px',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
    };
    return <img style={style} alt={'logo'} src={image} />;
};
