import { DeleteRequest, GetRequest, PostRequest } from '../services/httpRequest';

export const APIGetAllUsers = () => {
    return GetRequest('super-admin/get-all-users');
};
export const APIAdminInfoPost = (data: any) => {
    return PostRequest('super-admin/store/municipality', data);
};
export const APIAdminInfoPut = (data: any, userId: any) => {
    return PostRequest(`user/${userId}`, data);
};
export const APIAdminInfoDelete = (userId: any) => {
    return DeleteRequest(`user/${userId}`);
};
