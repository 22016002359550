import axios, { AxiosInstance } from 'axios';
import { getToken } from '../utils/helpers/localStorage.helpers';
import { errorToast } from '../components/common/toast/toast';
import { logout } from '../store/auth/auth.action';
import { store } from '../store/store';

const requestConfig = {
    baseURL: `https://api-idcm-demo.krafters.tech/api`,
    // timeout: 5000, //All request will wait 5 seconds before timeout
};

// const navigate = useNavigate();

const axiosInstance: AxiosInstance = axios.create(requestConfig);

axiosInstance.interceptors.request.use(
    (config: any) => {
        // add x-auth-token
        const accessToken = getToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response: any) => {
        if (response.status === 200 || response.status === 201) {
            // console.log('data loaded successfully');
        }
        return response.data;
    },
    (error: any) => {
        //handle errors
        // switch (error.response?.status) {
        //   case 400: {
        //     clearStorage();
        //     break;
        //   }
        //   case 401: {
        //     // @ts-ignore
        //     store.dispatch(logout())
        //     errorToast('तपाइको सत्र सकियको छ');
        //     history.back();
        //     break;
        //   }
        //   case 500: {
        //     clearStorage();
        //
        //     break;
        //   }
        //   default:
        //     clearStorage();
        //     break;
        // }
        if ([401, 403]?.includes(error.response?.status)) {
            //@ts-ignore
            store.dispatch(logout()).then((r) => r);
            // @ts-ignore
            store.dispatch(errorToast('तपाइको सत्र सकियको छ'));
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
