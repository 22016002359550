// @ts-nocheck
export const UserInfoDTO = {
    receive: (data: any) => {
        const a = [Object.entries(data).map(([key, val]) => [key, !!val ? String(val) : ''])];
        return Object.fromEntries(a[0]);
    },
};

export const EmployeePositionDTO = {
    receive: (data: any) => {
        if (!!data) {
            return {
                value: data?.user_id,
                label: `${data.full_nameNP}(${data?.position_nameNP})`,
            };
        }
    },
};
