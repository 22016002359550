import { isAuthenticated } from '../../utils/helpers/checkIfAuthenticated';
import { getToken } from '../../utils/helpers/localStorage.helpers';
import { LOGOUT_USER, SET_AUTH_DATA } from './auth.actionTypes';

interface IAuthenticationState {
    user: any;
    isLoggedIn: boolean;
    token: string | null;
}

const initialState: IAuthenticationState = {
    user: {},
    isLoggedIn: isAuthenticated(),
    token: getToken(),
};

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_AUTH_DATA:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true,
            };
        case LOGOUT_USER:
            return {
                ...state,
                token: '',
            };
        default:
            return state;
    }
};
