import { Home } from '../modules';
import { DisabilityInfo } from '../pages/DisabilityInfo';
import { DisableUserInfoForm } from '../pages/DisableUserInfoForm';
import { Employee } from '../pages/Employee';
import { EmployeeDesignation } from '../pages/EmployeeDesignation';
import { ChangePassword } from '../pages/ChangePassword';
import { Municipality } from '../pages/Municipality';
import { ErrorPage } from '../pages/ErrorPage';
import { SeniorCitizenInfo } from '../pages/SeniorCitizenInfo';
import { SeniorCitizenInfoForm } from '../pages/SeniorCitizenInfoForm';
import { DisableAddCategoryUserInfoForm } from '../pages/DisableAddCategoryUserInfoForm';
import { DisableInfoView } from '../pages/DisableInfoView';

export const DASHBOARD_ROUTES_SUPER_ADMIN = [
    { path: '/', element: <Home /> },
    { path: '/municipality', element: <Municipality /> },
    { path: '/setting', element: <ChangePassword /> },
];

export const DASHBOARD_ROUTES_ADMIN = [
    { path: '/', element: <Home /> },
    { path: '/senior-citizen', element: <SeniorCitizenInfo /> },
    { path: '/senior-citizen/add-new', element: <SeniorCitizenInfoForm /> },
    { path: '/disability-info', element: <DisabilityInfo /> },
    { path: '/disability-info/add-new', element: <DisableUserInfoForm /> },
    { path: '/disability-info/add-new-category', element: <DisableAddCategoryUserInfoForm /> },
    { path: '/disability-info/view', element: <DisableInfoView /> },
    { path: '/employee', element: <Employee /> },
    { path: '/designation', element: <EmployeeDesignation /> },
    { path: '/setting', element: <ChangePassword /> },
    { path: '/*', element: <ErrorPage /> },
];

export const DASHBOARD_ROUTES_STAFF = [
    { path: '/', element: <Home /> },
    { path: '/senior-citizen', element: <SeniorCitizenInfo /> },
    { path: '/senior-citizen/add-new', element: <SeniorCitizenInfoForm /> },
    { path: '/disability-info', element: <DisabilityInfo /> },
    { path: '/disability-info/add-new', element: <DisableUserInfoForm /> },
    { path: '/disability-info/add-new-category', element: <DisableAddCategoryUserInfoForm /> },
    { path: '/disability-info/view', element: <DisableInfoView /> },
    { path: '/setting', element: <ChangePassword /> },
    { path: '/*', element: <ErrorPage /> },
];
