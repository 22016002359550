import { Center, Group, Paper, SimpleGrid, Text, RingProgress } from '@mantine/core';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { APICardCount, APICardType } from '../../../../api';
// import { clearStorage } from '../../../../utils/helpers/localStorage.helpers';
import { useNavigate } from 'react-router';

const Home = () => {
    const navigate = useNavigate();
    const icons = {
        up: IconArrowUpRight,
        down: IconArrowDownRight,
    };

    const [count, setCount] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<any>();

    const card = async () => {
        const res = await APICardType();
        const cardTypes = res?.data?.data;
        sessionStorage.setItem('card-type', JSON.stringify(cardTypes));
    };

    const cardCount = async () => {
        try {
            const res = await APICardCount();
            if (res?.status == 1) {
                setTotalCount(res?.data?.totalCardCount == null ? 0 : res?.data?.totalCardCount);
                setCount(res?.data?.cardCount == null ? 0 : res?.data?.cardCount);
            } else {
                setCount([]);
            }
        } catch (e: any) {}
    };

    useEffect(() => {
        card();
        cardCount();
    }, []);

    const data: any = [
        {
            label: 'अपाङ्गता परिचय पत्र',
            stats: count ? count[0]?.disabledCardCount.toString() || '--' : '',
            progress: !!count[0] ? (count[0]?.disabledCardCount / totalCount) * 100 : 0,
            color: 'blue',
            icon: 'up',
            link: '/disability-info',
        },
        {
            label: 'जेष्ठ नागरिक परिचय पत्र',
            stats: count ? count[1]?.seniorCitizenCardCount.toString() || '--' : '',
            progress: !!count[0] ? (count[1]?.disabledCardCount / totalCount) * 100 : 0,
            color: 'green',
            icon: 'up',
            link: '/senior-citizen',
        },
        // {
        //   label: 'बिधुवा परिचय पत्र',
        //   stats: '12',
        //   progress: 12,
        //   color: "red",
        //   icon: 'up',
        //   link: '/'
        // },
        // {
        //   label: 'बाल पोषण परिचय पत्र',
        //   stats: '12',
        //   progress: 12,
        //   color: "skyblue",
        //   icon: 'up',
        //   link: '/'
        // }
    ];
    const stats = data.map((stat: any) => {
        // @ts-ignore
        const Icon = icons[stat.icon];
        return (
            <Paper withBorder radius="md" p="xs" key={stat.label} onClick={() => navigate(stat.link)}>
                <Group>
                    <RingProgress
                        size={80}
                        roundCaps
                        thickness={8}
                        sections={[{ value: stat.progress, color: stat.color }]}
                        label={
                            <Center>
                                <Icon size={22} stroke={1.5} />
                            </Center>
                        }
                    />

                    <div>
                        <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                            {stat.label}
                        </Text>
                        <Text weight={700} size="xl">
                            {stat.stats}
                        </Text>
                    </div>
                </Group>
            </Paper>
        );
    });
    return (
        <>
            <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {stats}
            </SimpleGrid>
        </>
    );
};

export default Home;
