import { Button, Group, Modal, Paper, PasswordInput, Table, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { errorToast, successToast } from '../../../common/toast/toast';
import { useNavigate } from 'react-router-dom';
import { IconEdit, IconTrash } from '@tabler/icons';
import { UserInfoDTO } from '../../../../utils/helpers/UserInfoFormater';
import { APIAdminInfoDelete, APIGetAllUsers, APIAdminInfoPost, APIAdminInfoPut } from '../../../../api/superadmin';

export const Municipality = () => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);
    const [userInfo, setUserInfo] = useState<any[]>();
    const form = useForm({
        initialValues: {
            id: '',
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },
    });

    const editEmployee = (data: any) => {
        setOpened(true);
        form.setValues(UserInfoDTO.receive(data));
    };

    const loadAllUsers = async () => {
        try {
            const res: any = await APIGetAllUsers();
            setUserInfo(res?.data?.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const deleteAdminHandler = async (data: any) => {
        try {
            const res: any = await APIAdminInfoDelete(data.id);
            if (res?.status == 1) {
                await loadAllUsers();
                successToast('नगरपालिका विवरण हटाइयो');
            } else {
                errorToast('Something went wrong, please try again!');
            }
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const ths = (
        <tr>
            <th>क्र.सं.</th>
            <th>Municipality Name</th>
            <th>Email</th>
            <th className={'w-[100px]'}>Remarks</th>
        </tr>
    );

    const rows = userInfo?.map((element, key) => (
        <tr key={key}>
            <td>{key + 1}</td>
            <td>{element.name}</td>
            <td>{element.email}</td>
            <td className={'w-[100px]  flex justify-around '}>
                <Button size={'sm'} className=" p-[0px] " variant="light" onClick={() => editEmployee(element)}>
                    <IconEdit className="m-0 p-0" size={20} />
                </Button>
                <Button className="p-[0px]" variant="light" onClick={() => deleteAdminHandler(element)}>
                    <IconTrash className="m-0 p-0" size={20} />
                </Button>
            </td>
        </tr>
    ));

    const submitHandler = async (data: any) => {
        try {
            if (!!data.id) {
                const res: any = await APIAdminInfoPut(data, data.id);
                if (res.status == 1) {
                    navigate('/municipality'), successToast('नगरपालिकाको विवरण सच्याइएको छ');
                    form.reset();
                    setOpened(false);
                    loadAllUsers();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            } else {
                const res: any = await APIAdminInfoPost(data);
                if (res.status == 1) {
                    navigate('/municipality'), successToast('नगरपालिकाको विवरण सुरक्षित गरिएको छ');
                    setOpened(false);
                    loadAllUsers();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };

    useEffect(() => {
        loadAllUsers();
    }, []);

    return (
        <Paper>
            <Modal opened={opened} onClose={() => setOpened(false)} title="कृपया नगरपालिकाको सहि विवरण भर्नुहोला !">
                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    <TextInput
                        label="नगरपालिकाको नाम(नेपलीमा)"
                        placeholder="नगरपालिकाको नाम लेख्नुहोस "
                        {...form.getInputProps('name')}
                    />
                    <TextInput label="इमेल ठेगाना" placeholder="example@gmail.com" {...form.getInputProps('email')} />
                    <PasswordInput label="Password" placeholder="password " {...form.getInputProps('password')} />
                    <PasswordInput
                        label="Confirm Password"
                        placeholder="retype password "
                        {...form.getInputProps('password_confirmation')}
                    />
                    <Group className={'mt-md'} position={'left'}>
                        <Button
                            className={'bg-primary-700'}
                            color={'blue'}
                            type={'submit'}
                            onClick={() => submitHandler(form.values)}
                        >
                            सेभ गर्नुहोस्
                        </Button>
                        <Button
                            variant={'outline'}
                            onClick={() => {
                                form.reset(), setOpened(false);
                            }}
                        >
                            रद्द गर्नुहोस्
                        </Button>
                    </Group>
                </form>
            </Modal>
            <Group className={'w-full flex justify-between mb-md  px-sm'}>
                <Text>नगरपालिका विवरण</Text>
                <Button
                    className={'bg-primary-700'}
                    onClick={() => {
                        setOpened(true), form.reset();
                    }}
                >
                    {' '}
                    नया नगरपालिका थप्नुहोस
                </Button>
            </Group>
            <Table striped highlightOnHover withBorder withColumnBorders>
                <thead>{ths}</thead>
                <tbody>{rows}</tbody>
            </Table>
        </Paper>
    );
};
