import { Box, Button, Group, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router';
import { APISendForgotPassword } from '../../../../api';
import { errorToast, successToast } from '../../../common/toast/toast';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const formData = new FormData();
    formData.append('email', form.values.email);

    const submitHandler = async () => {
        const res: any = await APISendForgotPassword(formData);
        if (res.status === 1) {
            navigate('/');
            successToast('A mail has been sent to your mail. Please check your mailbox.');
        } else {
            errorToast(res.error);
        }
    };

    return (
        <Box className="grid place-items-center h-[100vh]">
            <form className="w-[30rem]" onSubmit={form.onSubmit(() => submitHandler())}>
                <Group position="center" mb="md">
                    <Title order={2}>Forgot Password</Title>
                </Group>
                <TextInput
                    styles={{ label: { fontFamily: 'system-ui' } }}
                    required
                    label="Email"
                    placeholder="your@email.com"
                    {...form.getInputProps('email')}
                />
                <Group position="center" mt="md">
                    <Button type="submit" className="bg-[#228be6]" style={{ width: '100%' }}>
                        Forgot Password
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default ForgotPassword;
