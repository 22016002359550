import DashboardLayout from '../layouts/DashboardLayout';
// import SuperAdminLayout from '../layouts/SuperAdminLayout';
import { Login } from '../components/modules/auth/modules';

const RoleLayout = ({ role }: any) => {
    const handleRole: any = {
        // '1': <SuperAdminLayout />,
        '1': <DashboardLayout />,
        '2': <DashboardLayout />,
        '3': <DashboardLayout />,
        default: <Login />,
    };

    return handleRole[role] || handleRole['default'];
};

export default RoleLayout;
