import { Route, Routes } from 'react-router-dom';
import { AppShell } from '@mantine/core';
import { SideNavBar } from '../components/common/sideNavBar/SideNavbar';
import { TopBar } from '../components/common/topBar/TopBar';
import { useSelector } from 'react-redux';
import {
    DASHBOARD_ROUTES_ADMIN,
    DASHBOARD_ROUTES_STAFF,
    DASHBOARD_ROUTES_SUPER_ADMIN,
} from '../components/modules/dashboard/routes/DashboardRoutes';

const DashboardLayout = () => {
    const data = useSelector((state: any) => state.loggedUserReducer);
    return (
        <AppShell navbar={<SideNavBar />} header={<TopBar />} style={{ height: '100vh' }}>
            <div className="main-dashboard-section">
                <Routes>
                    {data?.user['user']?.role == 1
                        ? DASHBOARD_ROUTES_SUPER_ADMIN.map((v: any, key: number) => (
                              <Route path={v.path} element={v.element} key={key} />
                          ))
                        : data?.user['user']?.role == 2
                        ? DASHBOARD_ROUTES_ADMIN.map((v: any, key: number) => (
                              <Route path={v.path} element={v.element} key={key} />
                          ))
                        : DASHBOARD_ROUTES_STAFF.map((v: any, key: number) => (
                              <Route path={v.path} element={v.element} key={key} />
                          ))}
                </Routes>
            </div>
        </AppShell>
    );
};

export default DashboardLayout;
