import MainRoute from './routes';
import './App.scss';
import MainContainer from './containers/MainContainer';
import { Provider } from 'react-redux';
import { store } from './store/store';

const App = () => {
    return (
        <Provider store={store}>
            <MainContainer>
                <MainRoute />
            </MainContainer>
        </Provider>
    );
};

export default App;
