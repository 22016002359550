import { Button, Group, Modal, Paper, Table, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { errorToast, successToast } from '../../../common/toast/toast';
import { useNavigate } from 'react-router-dom';
import { IconEdit, IconTrash } from '@tabler/icons';
import {
    APIGetAllPositionsFiltered,
    APIPositionInfoDelete,
    APIPositionInfoPut,
    APIPostPositions,
} from '../../../../api/position';

export const EmployeeDesignation = () => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);
    const [designationInfo, setDesignationInfo] = useState<any[]>();
    const form = useForm({
        initialValues: {
            id: '',
            nameNP: '',
            nameEN: '',
        },
    });

    const editDesignation = (data: any) => {
        setOpened(true);
        form.setValues(data);
    };

    const loadDesignationInfo = async () => {
        try {
            const res: any = await APIGetAllPositionsFiltered();
            setDesignationInfo(res?.data?.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const deleteDesignationHandler = async (data: any) => {
        try {
            const res: any = await APIPositionInfoDelete(data.id);
            if (res?.status == 1) {
                await loadDesignationInfo();
                successToast('पदको विवरण हटाइयो');
            } else {
                errorToast('Something went wrong, please try again!');
            }
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const ths = (
        <tr>
            <th>क्र.सं.</th>
            <th>पद</th>
            <th>Designation</th>
            <th className={'w-[100px]'}>Remarks</th>
        </tr>
    );

    const rows = designationInfo?.map((element, key) => (
        <tr key={key}>
            <td>{key + 1}</td>
            <td>{element.nameNP}</td>
            <td>{element.nameEN}</td>
            <td className={'w-[100px]  flex justify-around '}>
                <Button size={'sm'} className=" p-[0px] " variant="light" onClick={() => editDesignation(element)}>
                    <IconEdit className="m-0 p-0" size={20} />
                </Button>
                <Button className="p-[0px]" variant="light" onClick={() => deleteDesignationHandler(element)}>
                    <IconTrash className="m-0 p-0" size={20} />
                </Button>
            </td>
        </tr>
    ));

    const submitHandler = async (data: any) => {
        try {
            if (!!data.id) {
                const res: any = await APIPositionInfoPut(data, data.id);
                if (res.status == 1) {
                    navigate('/designation'), successToast('पदको विवरण सच्याइएको छ');
                    form.reset();
                    setOpened(false);
                    loadDesignationInfo();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            } else {
                const res: any = await APIPostPositions(data);
                if (res.status == 1) {
                    navigate('/designation'), successToast('पद सुरक्षित गरिएको छ');
                    setOpened(false);
                    loadDesignationInfo();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };

    useEffect(() => {
        loadDesignationInfo();
    }, []);

    return (
        <Paper>
            <Modal opened={opened} onClose={() => setOpened(false)}>
                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    <TextInput label="पद(नेपलीमा)" placeholder="पद लेख्नुहोस " {...form.getInputProps('nameNP')} />
                    <TextInput label="Designation" placeholder=" in english" {...form.getInputProps('nameEN')} />

                    <Group className={'mt-md'} position={'left'}>
                        <Button
                            className={'bg-primary-700'}
                            color={'blue'}
                            type={'submit'}
                            onClick={() => submitHandler(form.values)}
                        >
                            सेभ गर्नुहोस्
                        </Button>
                        <Button
                            variant={'outline'}
                            onClick={() => {
                                form.reset(), setOpened(false);
                            }}
                        >
                            रद्द गर्नुहोस्
                        </Button>
                    </Group>
                </form>
            </Modal>
            <Group className={'w-full flex justify-between mb-md  px-sm'}>
                <Text>पदहरुको विवरणहरु</Text>
                <Button
                    className={'bg-primary-700'}
                    onClick={() => {
                        setOpened(true), form.reset();
                    }}
                >
                    {' '}
                    नया पद थप्नुहोस
                </Button>
            </Group>
            <Table className={'w-4/6 mx-auto'} striped highlightOnHover withBorder withColumnBorders>
                <thead>{ths}</thead>
                <tbody>{rows}</tbody>
            </Table>
        </Paper>
    );
};
