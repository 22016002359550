import { Button, Group, Modal, Paper, Select, Switch, Table, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { errorToast, successToast } from '../../../common/toast/toast';
import { useNavigate } from 'react-router-dom';
import {
    APIEmployeeInfo,
    APIEmployeeInfoPost,
    APIEmployeeInfoPut,
    APIEmployeeToggle,
    APIGetAllPositions,
} from '../../../../api/employee';
import { IconEdit } from '@tabler/icons';
import { UserInfoDTO } from '../../../../utils/helpers/UserInfoFormater';

export const Employee = () => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);
    const [employeeInfo, setEmployeeInfo] = useState<any[]>();
    const [allPosition, setAllPosition] = useState<[]>();
    const form = useForm({
        initialValues: {
            id: '',
            full_nameNP: '',
            full_nameEN: '',
            contact_no: '',
            position_id: '',
            email: '',
        },
    });

    const editEmployee = (data: any) => {
        setOpened(true);
        form.setValues(UserInfoDTO.receive(data));
    };

    const loadEmployeeInfo = async () => {
        try {
            const res: any = await APIEmployeeInfo();
            setEmployeeInfo(res?.data?.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const loadAllPositions = async () => {
        try {
            const res: any = await APIGetAllPositions();
            setAllPosition(res?.data?.data?.map((v:any)=> {return {'value': v.value.toString(), 'label': v.label}}));
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const handleStatus = async (id: any, status: boolean) => {
        const toggle = {delete_flag: !status}
        try {
            const res:any = await APIEmployeeToggle(toggle, id);
            if (res?.status == 1) {
                await loadEmployeeInfo();
                successToast('कर्मचारीको स्थिति परिवर्तन भयो');
            } else {
                errorToast('Something went wrong, please try again!');
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    const ths = (
        <tr>
            <th>क्र.सं.</th>
            <th>पुरा नाम</th>
            <th>Full Name</th>
            <th>Contact No.</th>
            <th>Designation</th>
            <th>Status</th>
            <th className={'w-[100px]'}>Remarks</th>
        </tr>
    );

    const rows = employeeInfo?.map((element, key) => (
        <tr key={key}>
            <td>{key + 1}</td>
            <td>{element.full_nameNP}</td>
            <td>{element.full_nameEN}</td>
            <td className={element.contact_no ?? 'text-center'}>{element.contact_no ?? '-'}</td>
            <td>{element.position_nameNP}</td>
            <td>
                <Switch 
                    color='green'
                    styles={{track: {background: 'red'}}}
                    checked={!element.delete_flag} onChange={(event) => {
                    const status = event.currentTarget.checked 
                    handleStatus(element.id, status)
                    }}
                />
            </td>
            <td className={'w-[100px]  flex justify-around '}>
                <Button size={'sm'} className=" p-[0px] " variant="light" onClick={() => editEmployee(element)}>
                    <IconEdit className="m-0 p-0" size={20} />
                </Button>
            </td>
        </tr>
    ));

    const submitHandler = async (data: any) => {
        try {
            if (!!data.id) {
                const res: any = await APIEmployeeInfoPut(data, data.id);
                if (res.status == 1) {
                    navigate('/employee'), successToast('कर्मचारीको विवरण सच्याइएको छ');
                    form.reset();
                    setOpened(false);
                    loadEmployeeInfo();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            } else {
                const res: any = await APIEmployeeInfoPost(data);
                if (res.status == 1) {
                    navigate('/employee'), successToast('कर्मचारीको विवरण सुरक्षित गरिएको छ');
                    setOpened(false);
                    loadEmployeeInfo();
                } else {
                    errorToast('Something went wrong, please try again!');
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };

    useEffect(() => {
        loadEmployeeInfo();
        loadAllPositions();
    }, []);

    return (
        <Paper>
            <Modal opened={opened} onClose={() => setOpened(false)} title="कृपया कर्मचारीको सहि विवरण भर्नुहोला !">
                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    <TextInput
                        label="पुरा नाम(नेपलीमा)"
                        placeholder="पुरा नाम लेख्नुहोस "
                        {...form.getInputProps('full_nameNP')}
                    />
                    <TextInput label="Full Name" placeholder="Full name in english" {...form.getInputProps('full_nameEN')} />
                    <TextInput label="सम्पर्क नं." placeholder="9876543210" {...form.getInputProps('contact_no')} />
                    <TextInput label="इमेल ठेगाना" placeholder="example@gmail.com" {...form.getInputProps('email')} />
                    {/*<PasswordInput*/}
                    {/*  label="Password"*/}
                    {/*  placeholder="password "*/}
                    {/*  {...form.getInputProps('password')}*/}
                    {/*/>*/}
                    {/*<PasswordInput*/}
                    {/*  label="Confirm Password"*/}
                    {/*  placeholder="retype password "*/}
                    {/*  {...form.getInputProps('password_confirmation')}*/}
                    {/*/>*/}
                    <Select
                        label="पद"
                        data={allPosition ? allPosition : ['कृपया कर्मचारी पद छैनन्']}
                        nothingFound={'nodata'}
                        placeholder="छान्नुहोस्"
                        {...form.getInputProps('position_id')}
                    />

                    <Group className={'mt-md'} position={'left'}>
                        <Button
                            className={'bg-primary-700'}
                            color={'blue'}
                            type={'submit'}
                            onClick={() => submitHandler(form.values)}
                        >
                            सेभ गर्नुहोस्
                        </Button>
                        <Button
                            variant={'outline'}
                            onClick={() => {
                                form.reset(), setOpened(false);
                            }}
                        >
                            रद्द गर्नुहोस्
                        </Button>
                    </Group>
                </form>
            </Modal>
            <Group className={'w-full flex justify-between mb-md  px-sm'}>
                <Text>कर्मचारी विवरण</Text>
                <Button
                    className={'bg-primary-700'}
                    onClick={() => {
                        setOpened(true), form.reset();
                    }}
                >
                    {' '}
                    नया कर्मचारी थप्नुहोस
                </Button>
            </Group>
            <Table striped highlightOnHover withBorder withColumnBorders>
                <thead>{ths}</thead>
                <tbody>{rows}</tbody>
            </Table>
        </Paper>
    );
};
