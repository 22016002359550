import { Header, Text } from '@mantine/core';

export const TopBar = () => {
    return (
        <>
            <Header
                style={{ width: 'calc(100vw-300px)' }}
                className={
                    'sm:bg-primary-700 md:bg-primary-700 lg:bg-primary-700  sm:ml-[250px]   text-white flex flex-col justify-center items-center pr-[300px]'
                }
                height={90}
            >
                <Text className={'text-lg  font-semibold'}> कटारी नगरपालिका</Text>
                <Text className={'font-light'}> कटारी, उदयपुर</Text>
                <Text className={'font-light'}> कोशी प्रदेश, नेपाल</Text>
            </Header>
        </>
    );
};
