import { Routes, Route } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../components/modules/auth/routes/PublicRoutes';

const PublicLayout = () => {
    return (
        <Routes>
            {PUBLIC_ROUTES.map((v, key) => (
                <Route path={v.path} element={v.element} key={key} />
            ))}
        </Routes>
    );
};

export default PublicLayout;
