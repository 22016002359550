import { APILoggedUser } from '../../api';
import * as actionTypes from './logged.user.actionTypes';
import { clearStorage } from '../../utils/helpers/localStorage.helpers';

export const setLoggedUser = (payload: any) => {
    return {
        type: actionTypes.SET_LOGGED_USER,
        payload: payload,
    };
};

export const loggedUser = () => async (dispatch: any) => {
    try {
        const res: any = await APILoggedUser();
        if (res) {
            dispatch(setLoggedUser(res));
        }
    } catch (e) {
        clearStorage();
    }
};
