export const gender = [
    { value: '1', label: 'पुरुष' },
    { value: '2', label: 'महिला' },
    { value: '3', label: 'अन्य' },
];

export const idCardTypes = [
    { value: '1', label: 'क' },
    { value: '2', label: 'ख' },
    { value: '3', label: 'ग' },
    { value: '4', label: 'घ' },
];
export const guardiansRelationNP = [
    { value: '1', label: 'बुबा' },
    { value: '2', label: 'आमा' },
    { value: '3', label: 'दिदी' },
    { value: '4', label: 'दाई/भाई' },
    { value: '5', label: 'हजुरबुबा' },
    { value: '6', label: 'हजुरआमा' },
    { value: '7', label: 'श्रीमती' },
    { value: '8', label: 'भाउजु' },
    { value: '9', label: 'मामा' },
    { value: '10', label: 'माइजू' },
    { value: '11', label: 'अरू' },
];

export const guardiansRelationEN = [
    { value: '1', label: 'Father' },
    { value: '2', label: 'Mother' },
    { value: '3', label: 'Sister' },
    { value: '4', label: 'Brother' },
    { value: '5', label: 'GrandFather' },
    { value: '6', label: 'GrandMother' },
];

export const maritalStatusData = [
    { value: '1', label: 'अविवाहित' },
    { value: '2', label: 'विवाहित' },
    { value: '3', label: 'सम्बन्धविच्छेद ' },
];

export const bloodGroupData = [
    { value: '1', label: 'A+' },
    { value: '2', label: 'A-' },
    { value: '3', label: 'AB+' },
    { value: '4', label: 'AB-' },
    { value: '5', label: 'B+' },
    { value: '6', label: 'B-' },
    { value: '7', label: 'O+' },
    { value: '8', label: 'O-' },
];
export const disability_nature = [
    { value: '1', label: 'शारीरिक अपाङ्गता' },
    { value: '2', label: 'दृष्टिसम्बन्धि अपाङ्गता' },
    { value: '3', label: 'सुनाइसम्बन्धि अपाङ्गता' },
    { value: '4', label: 'श्रवण दृष्टिबिहीन अपाङ्गता' },
    { value: '5', label: 'स्वर र बोलाई सम्बन्धि अपाङ्गता' },
    { value: '6', label: 'मानसिक वा मनोसामाजिक अपाङ्गता' },
    { value: '7', label: 'बौद्धिक अपाङ्गता' },
    { value: '8', label: 'अनुवंशीय रक्तश्राव सम्बन्धि अपाङ्गता' },
    { value: '9', label: 'आतिज्म सम्बन्धि अपाङ्गता' },
    { value: '10', label: 'बहुअपाङ्गता' },
];
export const disabilitySeverity = [
    { value: '1', label: 'पूर्ण अशक्त अपाङ्गता' },
    { value: '2', label: 'अति अशक्त अपाङ्गता' },
    { value: '3', label: 'मध्यम अपाङ्गता' },
    { value: '4', label: 'सामान्य अपाङ्गता' },
];

export const genderEN = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
    { value: '3', label: 'Other' },
];

export const idCardTypesEN = [
    { value: '1', label: 'A' },
    { value: '2', label: 'B' },
    { value: '3', label: 'C' },
    { value: '4', label: 'D' },
];

export const disability_natureEN = [
    { value: '1', label: 'Physically Disable' },
    { value: '2', label: 'Blind' },
    { value: '3', label: 'Deaf' },
    { value: '4', label: 'Visually impaired' },
    { value: '5', label: 'Dumb and Deaf' },
    { value: '6', label: 'Mental or psychosocial ' },
    { value: '7', label: 'Intellectual Disability ' },
    { value: '8', label: 'Hereditary bleeding disorders' },
    { value: '9', label: 'Autism ' },
    { value: '10', label: 'Multiple Disabilities' },
];

export const disabilitySeverityEN = [
    { value: '1', label: 'Fully ' },
    { value: '2', label: 'Severe ' },
    { value: '3', label: 'Moderate ' },
    { value: '4', label: 'General ' },
];

export const posts = [
    { value: '1', label: 'Chief Administrative Officer' },
    { value: '2', label: 'Accounts Officer' },
    { value: '3', label: 'Information technology Officer' },
    { value: '4', label: 'ICT engineer' },
    { value: '5', label: 'Office Helper' },
];
export const postsNP = [
    { value: '1', label: 'प्रमुख प्रशासकीय अधिकृत' },
    { value: '2', label: 'लेखा अधिकृत' },
    { value: '3', label: 'सूचना प्रविधि  अधिकारी' },
    { value: '4', label: 'ICT इन्जिनियर' },
    { value: '5', label: 'कार्यालय सहयोगी' },
];

export const showRows = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
];
