import { TextInput, Button, Group, Text, Select, Grid, Card, Modal, FileButton, FileInput } from '@mantine/core';
import { matches, useForm } from '@mantine/form';
import { errorToast, successToast } from '../../../common/toast/toast';
import { APIDisabilityInfoPost } from '../../../../api/disabilityInfo';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { IconCamera, IconCloudUpload, IconX } from '@tabler/icons';
import { PreviewImage } from './PreviewImage';
import { Camera } from 'react-camera-pro';
// @ts-ignore
import image1 from '../../../../assets/images/image1.jpg';

import {
    bloodGroupData,
    disabilitySeverity,
    disability_nature,
    gender,
    idCardTypes,
    maritalStatusData,
} from '../../../../utils/constants/Constants';
import { useLocation } from 'react-router';
import { EmployeePositionDTO, UserInfoDTO } from '../../../../utils/helpers/UserInfoFormater';
import { APIEmployeePosition } from '../../../../api/employee';
import { useSelector } from 'react-redux';
import { IMAGE_URL } from '../../../../config/baseURL';

export const DisableAddCategoryUserInfoForm = () => {
    const { state } = useLocation();
    const userData = useSelector((state: any) => state.loggedUserReducer);
    const municipalityID = userData?.user?.municipality;
    const [employeePost, setEmployeePost] = useState<any[]>([]);
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);

    const card_types = JSON.parse(sessionStorage.getItem('card-type') ?? '');
    const card_id = card_types?.find((v: any) => v.card_nameEN === 'disability identity card')?.id;

    const form = useForm({
        initialValues: {
            add_identity_no: '',
            full_nameEN: '',
            addressEN: '',
            districtEN: 'Udayapur',
            provinceEN: 'Koshi Province',
            contact_no: '',
            citizenship_no: '',
            date_of_birthEN: '',
            gender: '',
            blood_group: '',
            guardians_nameEN: '',
            add_disability_nature: '',
            add_disability_severity: '',
            add_card_class: '',
            marital_status: '',
            signature: '',
            full_nameNP: '',
            addressNP: '',
            districtNP: 'उदयपुर',
            provinceNP: 'कोशी प्रदेश',
            date_of_birthNP: '',
            guardians_nameNP: '',
            card_type_id: card_id,
            hospital_certificate: '',
            ward_no: '',
            add_approved_by: '',
            guardians_relation: '',
        },

        validate: {
            full_nameNP: matches(/[\u0900-\u097F]/, 'पुरा नाम नेपलीमा लेख्नुहोस्!'),
            guardians_nameNP: matches(/[\u0900-\u097F]+$/, 'नेपलीमा लेख्नुहोस्'),
        },
    });

    const employeePosition = async () => {
        const res: any = await APIEmployeePosition();
        const data = res.data.data.map((val: any) => EmployeePositionDTO.receive(val));

        setEmployeePost(data.map((v: any) => UserInfoDTO.receive(v)));
    };

    useEffect(() => {
        employeePosition();
        if (state !== null || undefined) {
            form.setValues(UserInfoDTO.receive(state));
        }
    }, [state]);
    const [selectedImage, setSelectedImage] = useState<Blob>();
    const [selectedHospitalDoc, setSelectedHospitalDoc] = useState<Blob>();
    //conversion of image
    function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new File(byteArrays, 'image', { type: contentType });
    }

    const submitHandler = async (data: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value as any);
        }
        if (!!selectedImage && typeof selectedImage == 'string') {
            // @ts-ignore
            let image1 = selectedImage?.split(',');
            var image2 = image1[1];

            const file = b64toBlob(image2, 'image/jpeg', 512);
            formData.append('image', file);
        } else {
            formData.append('image', selectedImage as any);
        }
        {
            !!selectedHospitalDoc ? formData.append('hospital_certificate', selectedHospitalDoc as any) : null;
        }

        //appending form data
        formData.append('approved_by', data?.add_approved_by);
        formData.append('identity_no', data?.add_identity_no);
        formData.append('disability_nature', data?.add_disability_nature);
        formData.append('disability_severity', data?.add_disability_severity);
        formData.append('card_class', data?.add_card_class);

        //removing unnecessary form data
        const removeUnnecessaryFormData = [
            'add_approved_by',
            'add_identity_no',
            'add_disability_nature',
            'add_disability_severity',
            'add_card_class',
        ];
        removeUnnecessaryFormData.map((removeData) => formData.delete(removeData));

        try {
            const res: any = await APIDisabilityInfoPost(formData);
            res?.status == 1
                ? (navigate('/disability-info'), successToast('तपाईको निबेदन पेस भएको छ|'))
                : errorToast('Something went wrong, please try again!');
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const camera = useRef<any>(null);

    // @ts-ignore
    return (
        <Grid className={'m-[0px] h-full w-full overflow-y-scroll '}>
            <Modal opened={opened} onClose={() => setOpened(false)} centered size={350} className={'w-full'}>
                <div className={'h-[180px] w-[150px] flex flex-col mx-auto items-center justify-center'}>
                    <Camera
                        aspectRatio={3 / 4}
                        ref={camera}
                        facingMode={'environment'}
                        errorMessages={{
                            noCameraAccessible: 'camera is not available',
                            permissionDenied: 'permissionDenied',
                            switchCamera: 'camera switch',
                            canvas: 'canvas',
                        }}
                    />
                    <Group className={'flex justify-around w-[300px] mb-md'}>
                        <Button
                            size={'xs'}
                            className={'bg-primary-700 mt-sm '}
                            onClick={() => {
                                setSelectedImage(camera.current.takePhoto()), setOpened(false);
                            }}
                        >
                            Take photo
                        </Button>
                        <Button size={'xs'} className={'bg-primary-700 mt-sm '} onClick={() => setOpened(false)}>
                            Cancel
                        </Button>
                    </Group>
                </div>
            </Modal>
            <Grid.Col className={''} offset={2} span={11}>
                <Card>
                    <Grid.Col offset={4}>
                        <Text className={'font-black text-xl underline'}> अपाङ्ग नागरिक तह बृद्धि दर्ता </Text>
                    </Grid.Col>
                    <form className={'w-full flex '} onSubmit={form.onSubmit((values) => submitHandler(values))}>
                        <Grid.Col span={4}>
                            <TextInput
                                className={'py-xs mt-md'}
                                withAsterisk
                                label="प.प.नं"
                                mask="00-00"
                                placeholder="परिचय पत्र नं"
                                {...form.getInputProps('add_identity_no')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="नागरिकता नं."
                                placeholder=""
                                {...form.getInputProps('citizenship_no')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="पुरा नाम"
                                placeholder="पुरा नाम लेख्नुहोस्"
                                {...form.getInputProps('full_nameNP')}
                                disabled
                            />
                            <Select
                                className={'py-xs'}
                                withAsterisk
                                label="लिङ्ग"
                                placeholder="छान्नुहोस्"
                                data={gender}
                                {...form.getInputProps('gender')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="जन्म मिति"
                                placeholder="२०२२-१०-१५"
                                {...form.getInputProps('date_of_birthNP')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="प्रदेश"
                                placeholder=""
                                disabled
                                {...form.getInputProps('provinceNP')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="ठेगाना"
                                placeholder="ठेगाना"
                                {...form.getInputProps('addressNP')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="वार्ड नं."
                                placeholder="वार्ड नं."
                                {...form.getInputProps('ward_no')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="संरक्षकको नाम"
                                placeholder="संरक्षकको पुरा नाम लेख्नुहोस् "
                                {...form.getInputProps('guardians_nameNP')}
                                disabled
                            />
                            <Select
                                className={'py-xs'}
                                withAsterisk
                                label="परिचय पत्रको प्रकार"
                                data={idCardTypes}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('add_card_class')}
                            />
                            <Select
                                className={'py-xs'}
                                withAsterisk
                                label="अपाङ्गताको प्रकृति"
                                data={disability_nature}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('add_disability_nature')}
                            />
                            <Select
                                className={'py-xs'}
                                data={disabilitySeverity}
                                withAsterisk
                                label="अपाङ्गताको गम्भीरता"
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('add_disability_severity')}
                            />
                        </Grid.Col>
                        <Grid.Col offset={0.5} span={4}>
                            <Group className={'flex '}>
                                <PreviewImage
                                    src={
                                        !!selectedImage
                                            ? selectedImage
                                            : state?.image
                                            ? `${
                                                  IMAGE_URL +
                                                  '/storage/municipality/' +
                                                  municipalityID +
                                                  '/userinfo/image/' +
                                                  state?.image
                                              }`
                                            : image1
                                    }
                                />
                                <Group className={'flex flex-col '}>
                                    {!!selectedImage ? (
                                        <Button
                                            onClick={() => setSelectedImage(undefined)}
                                            className={
                                                'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                            }
                                        >
                                            <IconX className={'mr-xs'} /> Remove image
                                        </Button>
                                    ) : (
                                        <FileButton onChange={(e: any) => setSelectedImage(e)} accept="image/png,image/jpeg">
                                            {(props) => (
                                                <Button
                                                    className={
                                                        'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                                    }
                                                    {...props}
                                                >
                                                    <IconCloudUpload className={'mr-xs'} /> Upload image
                                                </Button>
                                            )}
                                        </FileButton>
                                    )}
                                    <Button
                                        className={
                                            'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                        }
                                        onClick={() => setOpened(true)}
                                    >
                                        <IconCamera className={'mr-xs font-normal'} />
                                        {selectedImage ? 'Retake Picture' : 'Take Picture'}
                                    </Button>
                                </Group>
                            </Group>
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="Full Name"
                                placeholder=""
                                {...form.getInputProps('full_nameEN')}
                                disabled
                            />
                            <Select
                                className={'py-xs'}
                                label={'रक्त समूह'}
                                data={bloodGroupData}
                                placeholder={'छान्नुहोस्'}
                                {...form.getInputProps('blood_group')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="Date of Birth"
                                placeholder=""
                                {...form.getInputProps('date_of_birthEN')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="जिल्ला"
                                disabled
                                placeholder=""
                                {...form.getInputProps('districtNP')}
                            />
                            <TextInput
                                className={'py-xs'}
                                withAsterisk
                                label="Address"
                                placeholder="Address"
                                {...form.getInputProps('addressEN')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                label="सम्पर्क नं."
                                placeholder="९८७६५४३२१०"
                                {...form.getInputProps('contact_no')}
                                disabled
                            />
                            <TextInput
                                className={'py-xs'}
                                label="Guardians Name"
                                placeholder=""
                                {...form.getInputProps('guardians_nameEN')}
                                disabled
                            />
                            <Select
                                className={'py-xs'}
                                withAsterisk
                                label="बैबाहिक स्थिति"
                                data={maritalStatusData}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('marital_status')}
                                disabled
                            />
                            <Select
                                className={'py-xs'}
                                label="प्रमाणित गर्ने "
                                nothingFound="No result found"
                                placeholder="छान्नुहोस्"
                                data={employeePost}
                                {...form.getInputProps('add_approved_by')}
                            />
                            <FileInput
                                placeholder={'Upload hospital Certificate'}
                                label={'Hospital Certificate'}
                                onChange={(e: any) => setSelectedHospitalDoc(e)}
                                disabled
                            />
                            <Group position="right" mt="md">
                                <Button className={'bg-primary-700'} type="submit">
                                    Submit
                                </Button>
                            </Group>
                        </Grid.Col>
                    </form>
                </Card>
            </Grid.Col>
        </Grid>
    );
};
