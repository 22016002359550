import { GetRequest, PostRequest } from '../services/httpRequest';

export const APIEmployeeInfo = () => {
    return GetRequest('employee');
};
export const APIEmployeeInfoPost = (data: any) => {
    return PostRequest('employee', data);
};
export const APIEmployeeInfoPut = (data: any, employeeId: any) => {
    return PostRequest(`employee/${employeeId}`, data);
};
export const APIEmployeeToggle = (data: any, employeeId: any) => {
    return PostRequest(`employee/toggle-status/${employeeId}`, data);
};

export const APIEmployeePosition = () => {
    return GetRequest('employee?filter = true');
};

export const APIGetAllPositions = () => {
    return GetRequest('position');
};
