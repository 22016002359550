import * as actionTypes from './logged.user.actionTypes';

interface ILoggedUser {
    user: any;
}

const initialState: ILoggedUser = {
    user: {},
};

export const loggedUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_LOGGED_USER:
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};
