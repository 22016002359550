import { Button, Grid, Group, PasswordInput, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { APIChangePassword } from '../../../../api';
import { errorToast, successToast } from '../../../common/toast/toast';
import React from 'react';

export const ChangePassword = () => {
    const form = useForm({
        initialValues: {
            old_password: '',
            password: '',
            password_confirmation: '',
        },
        validate: {
            password: (value) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(value)
                    ? null
                    : 'Password should consist of minimum 8 characters, at least one uppercase letter, one lowercase letter and one number',
            password_confirmation: (value, values) => (value !== values.password ? 'Password did not match' : null),
        },
    });
    const formData = new FormData();
    formData.append('old_password', form.values.old_password);
    formData.append('password', form.values.password);
    formData.append('password_confirmation', form.values.password_confirmation);
    const navigate = useNavigate();

    const logoutHandler = () => {
        navigate('/');
    };
    const changePasswordHandler = async (a: any) => {
        try {
            const res: any = await APIChangePassword(a);
            res?.status == 1
                ? (navigate('/disability-info'), successToast(res?.message))
                : errorToast(res?.message ?? 'Something went wrong, please try again!');
        } catch (e) {
            await Promise.reject(e);
        }
    };

    return (
        <>
            <Grid justify={'center'} className={' w-full md:mt-md md:mt-3xl'}>
                <Grid.Col sm={6}>
                    <form onSubmit={form.onSubmit(() => changePasswordHandler(form.values))} className="w-full">
                        <Text className="sm:text-2xl lg:text-3xl text-center text-primary-700 font-poppins">Change password</Text>
                        <Text className="sm:text-xs lg:text-md text-center text-slate-400 font-poppins sm:mb-sm lg:mb-lg">
                            Your new password must be different from previous used passwords.
                        </Text>
                        <Group className="sm:w-11/12 lg:w-4/6 mx-auto">
                            <PasswordInput
                                className="w-full"
                                required={true}
                                name={'old_password'}
                                label="Old Password"
                                placeholder="Enter Old password"
                                // size={'md'}
                                {...form.getInputProps('old_password')}
                            />
                            <PasswordInput
                                className="w-full "
                                required={true}
                                name={'password'}
                                label="Password"
                                placeholder="Enter password"
                                // size={'md'}
                                {...form.getInputProps('password')}
                            />
                            <PasswordInput
                                className="w-full"
                                required={true}
                                name={'password_confirmation'}
                                label="Confirm Password"
                                placeholder="Enter password"
                                {...form.getInputProps('password_confirmation')}
                            />
                        </Group>
                        <div className={'grid grid-cols-2 gap-[5px] sm:w-11/12 lg:w-4/6 md:mx-auto mt-sm'}>
                            <Button
                                variant="outline"
                                size={'sm'}
                                className={' border-primary-700 text-primary-700 font-semibold w-full sm:text-xs'}
                                type={'reset'}
                                onClick={logoutHandler}
                            >
                                Cancel
                            </Button>
                            <Button size={'sm'} className={'bg-primary-700 sm:text-xs'} type={'submit'}>
                                Change Password
                            </Button>
                        </div>
                    </form>
                </Grid.Col>
            </Grid>
        </>
    );
};
