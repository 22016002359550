import { DeleteRequest, GetRequest, PostRequest } from '../services/httpRequest';

export const APIGetAllPositions = () => {
    return GetRequest('position');
};
export const APIGetAllPositionsFiltered = () => {
    return GetRequest('position?all=true');
};
export const APIPostPositions = (data: any) => {
    return PostRequest('position', data);
};
export const APIPositionInfoPut = (data: any, positionId: any) => {
    return PostRequest(`position/${positionId}`, data);
};
export const APIPositionInfoDelete = (positionId: any) => {
    return DeleteRequest(`position/${positionId}`);
};
