import { BrowserRouter } from 'react-router-dom';
import { BaseTheme } from '../theme';
import React from 'react';

interface IMainContainer {
    children: React.ReactNode;
}

const MainContainer = (props: IMainContainer) => {
    return (
        <BrowserRouter>
            <BaseTheme>{props.children}</BaseTheme>
        </BrowserRouter>
    );
};

export default MainContainer;
